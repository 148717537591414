import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import LeadIn from "../components/Content/LeadIn/Index";
import ContactForm from "../components/Content/Forms/Index";

// markup
const IndexPage: React.FC = () => {
  return (
    <>
    <Layout staticHtml={<ContactForm />}>
      <Seo
        title="Contact Us"
        description="Contact us through the form below with any enquiry and we’ll be in touch within 48 hours."
      />
      <BannerBasic title="Contact" />
      <LeadIn
        brandMediaTitle="Logo"
        brandMediaUrl="/logos/logo.svg"
        brandMediaWidth="306px"
        summary="Contact us through the form below with any enquiry and we’ll be in touch within 48 hours."
      >
        {/* Also imported into /src/components/Layouts/Layouts.tsx */}
        {/* Netlify bots can't see this so it's added to the above via props */}
        <ContactForm />
      </LeadIn>
    </Layout>
    </>
  );
};

export default IndexPage;

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";

// components
import FormLabel from "./Label";

// Shape of form values
interface FormValues {
  name: string;
  email: string;
  phone: string;
  type: string;
  message: string;
}

function encode(data: any) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [problem, setProblem] = useState(false);
  const [submission, setSubmission] = useState(false);

  if (!submission) {
    return (
      <div className="uk-width-1-1 uk-width-2-3@m uk-width-1-2@l uk-margin-auto uk-margin-xlarge-top">
        <Formik
          initialValues={{
            "form-name": "contact-form",
            "bot-field": "",
            name: "",
            email: "",
            phone: "",
            type: "",
            message: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            // show loader
            setLoading(true);
            setProblem(false);
            actions.setSubmitting(false);
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({
                ...values,
              }),
            })
              .then(function () {
                setLoading(false);
                setSubmission(true);
              })
              .catch(function () {
                setProblem(true);
                setLoading(false);
              });
          }}
          validate={(values: any) => {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            const errors: FormikErrors<FormValues> = {};
            if (!values.name) {
              errors.name = "A name is required";
            }
            if (!values.email || !emailRegex.test(values.email)) {
              errors.email = "A valid email address is required";
            }
            if (!values.type) {
              errors.type = "A type is required";
            }
            if (!values.message) {
              errors.message = "A message is required";
            }
            return errors;
          }}
        >
          {({ errors }) => (
            <Form
              autoComplete="off"
              noValidate
              className="uk-form-stacked uk-text-left"
              name="contact-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              {errors.name || errors.email || errors.message ? (
                <div role="alert" className="uk-text-small uk-alert">
                  <h2 className="uk-h5">There are errors on the form</h2>
                  <ul className="uk-list uk-list-collapse">
                    <ErrorMessage
                      render={(msg) => <li>{msg}</li>}
                      name="name"
                    />
                    <ErrorMessage
                      render={(msg) => <li>{msg}</li>}
                      name="email"
                    />
                    <ErrorMessage
                      render={(msg) => <li>{msg}</li>}
                      name="type"
                    />
                    <ErrorMessage
                      render={(msg) => <li>{msg}</li>}
                      name="message"
                    />
                  </ul>
                </div>
              ) : null}
              <Field type="hidden" name="bot-field" />
              <div className="uk-margin">
                <FormLabel label="Full name" forLabel="name" required={true} />
                <div className="uk-form-controls">
                  <Field
                    className={
                      "uk-input c-required " +
                      (errors.name ? "uk-form-danger" : "uk-form-success")
                    }
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Jane Smithers"
                    required
                  />
                </div>
              </div>
              <div className="uk-margin">
                <FormLabel
                  label="Email address"
                  forLabel="email"
                  required={true}
                />
                <div className="uk-form-controls">
                  <Field
                    className={
                      "uk-input c-required " +
                      (errors.name ? "uk-form-danger" : "uk-form-success")
                    }
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@email.com"
                    required
                  />
                </div>
              </div>
              <div className="uk-margin">
                <FormLabel
                  label="Contact number"
                  forLabel="phone"
                  required={false}
                />
                <div className="uk-form-controls">
                  <Field
                    className="uk-input"
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Mobile number"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <FormLabel label="Type" forLabel="type" required={true} />
                <div className="uk-form-controls">
                  <Field
                    className={
                      "uk-select " +
                      (errors.name ? "uk-form-danger" : "uk-form-success")
                    }
                    as="select"
                    id="type"
                    name="type"
                    required
                  >
                    <option value="">Select enquiry type</option>
                    <option value="General Enquiry">General enquiry</option>
                    <option value="Request training or site demonstration">
                      Request training or site demonstration
                    </option>
                    <option value="Technical enquiry">Technical enquiry</option>
                    <option value="Further information about the Conex Bänninger system">
                      Further information about the Conex Bänninger system
                    </option>
                  </Field>
                </div>
              </div>
              <div className="uk-margin">
                <FormLabel label="Message" forLabel="message" required={true} />
                <div className="uk-form-controls">
                  <Field
                    className={
                      "uk-textarea " +
                      (errors.name ? "uk-form-danger" : "uk-form-success")
                    }
                    as="textarea"
                    name="message"
                    id="message"
                    rows={10}
                    placeholder="Please provide your details"
                    required
                  ></Field>
                </div>
              </div>
              <div
                className="uk-flex uk-flex-middle uk-grid-small uk-margin-medium-top"
                uk-grid=""
              >
                <div className="uk-width-1-3">
                  <button
                    className="uk-button uk-button-primary uk-width-1-1"
                    type="submit"
                    disabled={loading ? true : false}
                  >
                    {loading ? <span uk-spinner="ratio: 0.6"></span> : "Submit"}
                  </button>
                </div>
                <div className="uk-width-2-3">
                  {loading && (
                    <p className="c-text-2xsmall">
                      Please wait while we submit your details...
                    </p>
                  )}
                  {problem && (
                    <p className="c-text-2xsmall">
                      Sorry there was a problem sending your request. <br />
                      Please refresh and try again.
                    </p>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  } else {
    return (
      <div className="uk-width-1-1 uk-width-2-3@m uk-width-1-2@l uk-margin-auto uk-margin-xlarge-top">
        <h3>Thank you</h3>
        <p>We will be in contact with you soon.</p>
      </div>
    );
  }
};

export default ContactForm;
